// src/sections/ProjectSection.js
import React from "react";
import { Helmet } from "react-helmet-async";
import ProjectCard from "../components/ProjectCard";
import "./ProjectSection.css"; // Import appropriate styles
import Dislexia from "../assets/images/dislexia.png";
import CASlogo from "../assets/images/caslogo.svg";
import GTALogo from "../assets/images/gta.jpeg";
import CodexMerger from "../assets/images/CodexMerger.png";
const projectData = [
  {
    title: "Dislexia Chrome Extension",
    description:
      "A Chrome extension designed to assist users with dyslexia by providing text-to-speech (TTS) and speech-to-text (STT) features, as well as an overlay for text highlighting.",
    technologies: ["JavaScript", "Chrome Extensions API", "HTML", "CSS", "Web Speech API"],
    link: "/dislexia",
    image: Dislexia,
  },
  {
    title: "Central Asian Success",
    description:
      "A 501(c)(3) non-profit organization dedicated to empowering young Central Asians in America by providing mentorship, networking opportunities, and fostering a supportive community to help each member reach their fullest potential.",
    technologies: ["Website Development", "Social Media Platforms", "Online Communication Tools", "Content Creation"],
    link: "/projects-folder/Central_Asian_Success/CAS.html", // Replace with the actual link when available
    image: CASlogo, // Replace with the actual path or URL to the logo/image
  },
  {
    title: "Grand Theft Auto VI Store",
    description:
      "Grand Theft Auto VI heads to the state of Leonida, home to the neon-soaked streets of Vice City and beyond in the biggest, most immersive evolution of the Grand Theft Auto series yet. Coming 2025 to PlayStation 5 and Xbox Series X|S.",
    technologies: ["Angular", "TypeScript", "Node.js", "HTML ", "CSS "],
    link: "https://muindollar.com/",
    image: GTALogo,
  },
  {
    title: "Codex Merger",
    description:
      "        Codex Merger seamlessly combines and manages your code files, streamlining your development workflow with ease and security. Enhance your productivity and organization effortlessly.",
    technologies: ["Python", "CustomTkinter", "UI", "C ", "Stripe", "Payment Gateway"],
    link: "/store",
    image: CodexMerger,
  },

  // Add more project data here as needed
];

const ProjectSection = () => {
  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "ItemList",
      itemListElement: projectData.map((project, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@type": project.type, // Dynamically set the type
          name: project.title,
          description: project.description,
          image: `${process.env.REACT_APP_BASE_URL}${project.image}`, // Ensure the URL is absolute
          url: `${process.env.REACT_APP_BASE_URL}${project.link}`,
          softwareVersion: project.type === "SoftwareApplication" ? "1.0.0" : undefined,
          applicationCategory: project.type === "SoftwareApplication" ? "UtilitiesApplication" : undefined,
          author:
            project.type === "SoftwareApplication"
              ? {
                  "@type": "Person",
                  name: "Muyinjon Turobov",
                  url: "https://muyin.me/",
                }
              : undefined,
          datePublished: "2023-01-01", // Update accordingly
          technologiesUsed: project.technologies.map((tech) => ({
            "@type": "DefinedTerm",
            name: tech,
          })),
        },
      })),
    };
  };

  return (
    <section className="project-section" aria-labelledby="projects-heading">
      {/* Structured Data */}
      <script type="application/ld+json">{JSON.stringify(generateStructuredData())}</script>

      <header>
        <h2 id="projects-heading">📁 My Projects</h2>
        <p className="project-intro">Showcasing a diverse range of projects, highlighting skills in development, design, and problem-solving.</p>
      </header>
      <div className="project-cards">
        {projectData.map((project, index) => (
          <article className="project-card-wrapper bounce-in" key={index}>
            <ProjectCard
              image={project.image}
              title={project.title}
              description={project.description}
              technologies={project.technologies}
              link={project.link}
            />
          </article>
        ))}
      </div>
    </section>
  );
};

export default ProjectSection;
