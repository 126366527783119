// src/sections/ProfileSection.js
import React from "react";
import ProfileCard from "../components/ProfileCard";
import "./ProfileSection.css"; // Create appropriate styles

const ProfileSection = () => {
  return (
    <section className="profile-section bounce-in">
      {/* Add Header Component or Other Elements Here */}
      <ProfileCard />
      <div className="About">
        {/* <h1>About Me</h1> */}

        <h3 className="About-Text">
          Hello! I’m <strong>Muyinjon Turobov</strong>, a <strong>visionary entrepreneur</strong> and <strong>skilled developer</strong> dedicated to driving{" "}
          <strong>technology and business innovation</strong>. With a robust background in <strong>software development</strong> and a passion for{" "}
          <strong>digital transformation</strong>, I specialize in creating and leading <strong>startups</strong> that deliver{" "}
          <strong>cutting-edge tech solutions</strong>. Beyond my entrepreneurial ventures, I host a <strong>Central Asian podcast</strong>,{" "}
          <em>Central Asian Success</em>, where I engage with industry leaders and innovators to share insights on achieving success in the tech and business
          landscapes of Central Asia.
        </h3>
        {/* 
        <p>
          My unique blend of <strong>technical expertise</strong> and <strong>strategic business strategies</strong> enables me to foster growth and create
          impactful solutions in the tech industry. Whether developing robust applications, spearheading innovative business ventures, or producing insightful
          podcast content, I am committed to pushing the boundaries of what's possible and helping businesses thrive in the digital age.
        </p> */}

        {/* <h2>Key Highlights</h2>
        <ul>
          <li>
            <strong>Entrepreneurship:</strong> Proven track record of launching and scaling successful startups in the tech sector.
          </li>
          <li>
            <strong>Software Development:</strong> Expertise in full-stack development, creating scalable and efficient software solutions.
          </li>
          <li>
            <strong>Business Innovation:</strong> Skilled in identifying market opportunities and implementing innovative strategies to drive business growth.
          </li>
          <li>
            <strong>Leadership:</strong> Experienced in leading diverse teams to achieve ambitious goals and deliver high-quality projects.
          </li>
          <li>
            <strong>Digital Transformation:</strong> Adept at guiding businesses through digital transformation initiatives to enhance operational efficiency
            and competitiveness.
          </li>
          <li>
            <strong>Central Asian Podcasting:</strong> Host of <em>Central Asian Success</em>, a podcast dedicated to showcasing success stories and strategies
            within Central Asia’s tech and business communities.
          </li>
          <li>
            <strong>Tech Passion:</strong> Continuously exploring emerging technologies to stay ahead in the rapidly evolving tech landscape.
          </li>
          <li>
            <strong>Central Asian Advocacy:</strong> Championing the growth and recognition of Central Asian innovations in the global market.
          </li>
        </ul> */}
      </div>
    </section>
  );
};

export default ProfileSection;
