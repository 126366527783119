import React from "react";
import "./Header.css"; // Assuming you are using a CSS file for styles
import { FaUser, FaBolt, FaEnvelope, FaArrowUp, FaHome, FaCode, FaFileCode, FaStore } from "react-icons/fa"; // Importing icons
import profileImage from "../assets/images/profile.png"; // Correct import path

const Header = () => {
  return (
    <div className="header">
      {/* Profile Picture */}
      <div className="profile-pic">
        <img src={profileImage} /* Replace with the actual image URL */ alt="Profile" className="profile-img" />
      </div>

      {/* Icons */}
      <div className="icons">
        <a href="/">
          <FaHome className="icon" />
          <span className="icon-label"> Home</span>
        </a>
        <a href="/projects">
          <FaFileCode className="icon" />
          <span className="icon-label"> Projects</span>
        </a>
        <a href="/store">
          <FaStore className="icon" />
          <span className="icon-label"> Store</span>
        </a>
        <a href="/contact">
          <FaEnvelope className="icon" />
          <span className="icon-label"> Contacts</span>
        </a>
      </div>
    </div>
  );
};

export default Header;
