// src/pages/HomePage.js
import React from "react";
import { Helmet } from "react-helmet-async";
import ProfileSection from "../sections/ProfileSection";
import ExperienceSection from "../sections/ExperienceSection";
import EducationSection from "../sections/EducationSection";
import ProjectSection from "../sections/ProjectSection";

const HomePage = () => (
  <div className="home-page">
    <Helmet>
      {/* Primary Meta Tags */}
      <title>Muyinjon Turobov | Visionary Entrepreneur | Developer | Central Asian Podcaster</title>
      <meta
        name="description"
        content="Muyinjon Turobov is an entrepreneur, full-stack developer, and Central Asian podcaster. Explore his profile, experience, education, and projects focused on tech and business innovation."
      />
      <link rel="canonical" href="https://muyin.me/" />

      {/* Viewport Meta Tag for Responsive Design */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Open Graph Meta Tags for Social Media Sharing */}
      <meta property="og:title" content="Muyinjon Turobov | Visionary Entrepreneur | Developer | Central Asian Podcaster" />
      <meta
        property="og:description"
        content="Discover Muyinjon Turobov, a visionary entrepreneur and skilled developer passionate about technology and business innovation. Host of the Central Asian podcast 'Central Asian Success'. Explore his profile, experience, education, and projects."
      />
      <meta property="og:url" content="https://muyin.me/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://muyin.me/images/og-image.jpg" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Muyin.me" />

      {/* Twitter Card Meta Tags for Enhanced Twitter Sharing */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Muyinjon Turobov | Visionary Entrepreneur | Developer | Central Asian Podcaster" />
      <meta
        name="twitter:description"
        content="Meet Muyinjon Turobov, an entrepreneur and developer passionate about technology and business innovation. Host of the Central Asian podcast 'Central Asian Success'. Learn about his journey, projects, and insights."
      />
      <meta name="twitter:image" content="https://muyin.me/images/twitter-image.jpg" />

      {/* Favicon and Apple Touch Icons are managed in public/index.html */}

      {/* Robots Meta Tag to Control Search Engine Indexing */}
      <meta name="robots" content="index, follow" />

      {/* Structured Data (JSON-LD) for Enhanced Search Engine Understanding */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Muyinjon Turobov",
            "jobTitle": "Visionary Entrepreneur & Developer",
            "url": "https://muyin.me/",
            "sameAs": [
              "https://www.linkedin.com/in/muyinjon-turobov",
              "https://twitter.com/MuyinjonT",
              "https://github.com/Muyinjon",
              "https://x.com/RealRapperM",
              "https://www.instagram.com/muindollar",
              "https://www.tiktok.com/@muindollar"
            ],
            "description": "Visionary entrepreneur and skilled developer passionate about tech and business innovation. Host of the Central Asian podcast Central Asian Success.",
            "image": "https://muyin.me/images/profile-photo.jpg",
            "alumniOf": "CUNY Brooklyn College",
            "knowsAbout": ["Entrepreneurship", "Software Development", "Business Innovation", "Digital Transformation", "Podcasting"]
          }
        `}
      </script>

      {/* Podcast Structured Data (JSON-LD) */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "PodcastSeries",
            "name": "Central Asian Success",
            "url": "https://www.youtube.com/watch?v=5nhTlFXA-6U&list=PL7b4h1aIEomYh9wyM6f6OGWqG7P2HYbu5",
            "description": "Central Asian Success is a podcast hosted by Muyinjon Turobov, featuring interviews with industry leaders and innovators in Central Asia's tech and business sectors.",
            "image": "https://muyin.me/images/podcast-image.jpg",
            "author": {
              "@type": "Person",
              "name": "Muyinjon Turobov"
            }
          }
        `}
      </script>
    </Helmet>
    <ProfileSection />
    <ExperienceSection />
    <EducationSection />
    <ProjectSection />
  </div>
);

export default HomePage;
