import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../styles/Storepage.css";

const StorePage = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // Dynamically load the Stripe Buy Button script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;

    script.onload = () => {
      setScriptLoaded(true);
    };

    script.onerror = () => {
      console.error("Stripe Buy Button script failed to load.");
    };

    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="store-page">
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Codex Merger | Muyinjon Turobov | Software Store</title>
        <meta
          name="description"
          content="Purchase Codex Merger by Muyinjon Turobov. Streamline your development workflow with our innovative code management tool. Secure, efficient, and user-friendly."
        />
        <link rel="canonical" href="https://muyin.me/store" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Codex Merger | Muyinjon Turobov | Software Store" />
        <meta
          property="og:description"
          content="Get Codex Merger from Muyinjon Turobov's store. Enhance your development workflow with our tool designed for efficient code management and organization."
        />
        <meta property="og:url" content="https://muyin.me/store" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://muyin.me/images/social-image.jpg" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Muyin.me" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Codex Merger | Muyinjon Turobov | Software Store" />
        <meta
          name="twitter:description"
          content="Purchase Codex Merger from Muyinjon Turobov's store. Streamline your development workflow with our innovative code management tool. Secure, efficient, and user-friendly."
        />
        <meta name="twitter:image" content="https://muyin.me/images/social-image.jpg" />

        {/* Structured Data (JSON-LD) for Product */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "Codex Merger",
              "image": "https://muyin.me/images/public/images/CodexMerger.png",
              "description": "Codex Merger streamlines your development workflow by effortlessly combining and managing multiple code files, enhancing productivity and ensuring organized, secure codebases.",
              "brand": {
                "@type": "Person",
                "name": "Muyinjon Turobov"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://muyin.me/store",
                "priceCurrency": "USD",
                "price": "5.00",
                "availability": "https://schema.org/InStock"
              }
            }
          `}
        </script>
      </Helmet>
      <h1 className="store-title">Codex Merger</h1>
      <h3 className="store-description">
        Codex Merger streamlines your development workflow by effortlessly combining and managing multiple code files, enhancing productivity and ensuring
        organized, secure codebases.
      </h3>

      <div className="store-buy-button">
        {scriptLoaded ? (
          <stripe-buy-button
            buy-button-id="buy_btn_1QXbsVJQ6QyaaTG3TBYWp4aa"
            publishable-key="pk_live_51QXTruJQ6QyaaTG325oyTk50TXpzw6QHrMInKddRujFaPch5Qai76sQGuH96ns0rQH6lXc8hqLXjUnQ1SrhzZyIg006EfUUsto"
          >
            Buy Codex Merger
          </stripe-buy-button>
        ) : (
          <p>Loading payment options...</p>
        )}
      </div>

      <div className="features-section">
        <h2 className="features-title">Features</h2>
        <ul className="features-list">
          <li>
            <strong>File Management:</strong> Add and remove multiple code files with ease, managing your projects efficiently.
          </li>
          <li>
            <strong>Annotations:</strong> Automatically include file path annotations and tree structures for better code organization.
          </li>
          <li>
            <strong>Customization:</strong> Choose from various wrapper styles, enable auto language detection, and include metadata like file size and
            modification time.
          </li>
          <li>
            <strong>Filters:</strong> Clean your code by removing empty lines, trimming trailing spaces, excluding comment lines, and filtering out non-ASCII
            characters.
          </li>
          <li>
            <strong>Chunking:</strong> Split your merged files into manageable chunks, specifying the number of lines per chunk for better readability.
          </li>
          <li>
            <strong>Presets:</strong> Use predefined settings or save and load your custom configurations for quick and consistent file merging.
          </li>
          <li>
            <strong>User-Friendly GUI:</strong> Navigate through an intuitive interface built with CustomTkinter, making file merging accessible to all users.
          </li>
          <li>
            <strong>Token Estimation:</strong> Estimate the number of tokens in your merged files, useful for integrations with services like OpenAI's GPT
            models.
          </li>
          <li>
            <strong>Author & Company Information:</strong> Embedded metadata displaying the creator and associated company, reinforcing trust and credibility.
          </li>
        </ul>
      </div>

      <div className="payment-policies">
        <h2>Payment Policies</h2>
        <ul>
          <li>
            <strong>Honor System:</strong> Customers can choose to pay any amount between <strong>$1</strong> and <strong>$420.69</strong>.
          </li>
          <li>
            <strong>No Refunds:</strong> All sales are final. Please ensure that you are satisfied with your purchase before completing the transaction.
          </li>
          <li>
            <strong>Download Instructions:</strong> After completing the payment, you will be redirected to a download page where you can download the{" "}
            <strong>Codex Merger</strong> executable file.
          </li>
          <li>
            <strong>Support:</strong> If you encounter any issues with the download or have any questions, please reach out to us at{" "}
            <a href="mailto:ceoturobov@gmail.com">CeoTurobov@gmail.com</a>.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StorePage;
