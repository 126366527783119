// src/pages/ProjectsPage.js
import React from "react";
import { Helmet } from "react-helmet-async";
import ProjectSection from "../sections/ProjectSection";

const ProjectsPage = () => {
  return (
    <div className="projects-page">
      <Helmet>
        {/* Primary Meta Tags */}
        <title>My Projects | Muyinjon Turobov | Developer</title>
        <meta
          name="description"
          content="Explore the diverse range of projects by Muyinjon Turobov, a skilled developer and visionary entrepreneur. Discover innovative solutions in tech and business."
        />
        <link rel="canonical" href="https://muyin.me/projects" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="My Projects | Muyinjon Turobov | Developer" />
        <meta
          property="og:description"
          content="Dive into the portfolio of Muyinjon Turobov, showcasing a variety of innovative projects in technology and business. Learn about the solutions and technologies used."
        />
        <meta property="og:url" content="https://muyin.me/projects" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://muyin.me/images/social-image.jpg" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Muyin.me" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="My Projects | Muyinjon Turobov | Developer" />
        <meta
          name="twitter:description"
          content="Explore the portfolio of Muyinjon Turobov, a skilled developer and visionary entrepreneur. Discover innovative projects in tech and business."
        />
        <meta name="twitter:image" content="https://muyin.me/images/social-image.jpg" />

        {/* Structured Data (JSON-LD) for Projects Page */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "My Projects",
              "url": "https://muyin.me/projects",
              "description": "A showcase of projects by Muyinjon Turobov, highlighting innovative solutions in technology and business.",
              "mainEntity": {
                "@type": "Person",
                "name": "Muyinjon Turobov",
                "jobTitle": "Developer & Entrepreneur",
                "alumniOf": "CUNY Brooklyn College",
                "sameAs": [
                  "https://www.linkedin.com/in/muyinjon-turobov",
                  "https://github.com/Muyinjon",
                  "https://x.com/RealRapperM",
                  "https://www.instagram.com/muindollar",
                  "https://www.tiktok.com/@muindollar"
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <h1 className="projects-title">My Projects</h1>
      <div className="projects-list"></div>
      <ProjectSection />
    </div>
  );
};

export default ProjectsPage;
