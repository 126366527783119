import React from "react";
import { Helmet } from "react-helmet-async";
import { FaLinkedin, FaGithub, FaEnvelope, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "../styles/Contactpage.css"; // Import the new CSS file

const ContactPage = () => {
  return (
    <div className="contact-page">
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Contact Muyinjon Turobov | Get in Touch</title>
        <meta
          name="description"
          content="Get in touch with Muyinjon Turobov, a visionary entrepreneur and skilled developer. Connect via LinkedIn, GitHub, Twitter, Instagram, TikTok, or email."
        />
        <link rel="canonical" href="https://muyin.me/contact" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Contact Muyinjon Turobov | Get in Touch" />
        <meta
          property="og:description"
          content="Reach out to Muyinjon Turobov, a visionary entrepreneur and skilled developer. Connect through LinkedIn, GitHub, Twitter, Instagram, TikTok, or email for collaborations and inquiries."
        />
        <meta property="og:url" content="https://muyin.me/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://muyin.me/images/social-image.jpg" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Muyin.me" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Muyinjon Turobov | Get in Touch" />
        <meta
          name="twitter:description"
          content="Connect with Muyinjon Turobov, a visionary entrepreneur and skilled developer. Reach out via LinkedIn, GitHub, Twitter, Instagram, TikTok, or email for collaborations and inquiries."
        />
        <meta name="twitter:image" content="https://muyin.me/images/social-image.jpg" />

        {/* Structured Data (JSON-LD) for Contact Page */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ContactPage",
              "name": "Contact Muyinjon Turobov",
              "url": "https://muyin.me/contact",
              "description": "Contact Muyinjon Turobov to discuss collaborations, inquiries, or any questions you may have about his entrepreneurial and development ventures.",
              "mainEntity": {
                "@type": "Person",
                "name": "Muyinjon Turobov",
                "email": "Ceoturobov@gmail.com",
                "telephone": "+1 (347) 435 - 4914",
                "sameAs": [
                  "https://www.linkedin.com/in/muyinjon-turobov",
                  "https://github.com/Muyinjon",
                  "https://x.com/RealRapperM",
                  "https://www.instagram.com/muindollar",
                  "https://www.tiktok.com/@muindollar"
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <h1 className="contact-title">Contact Us</h1>
      <div className="contact-social-contact">
        <h2>Social Media</h2>
        <ul className="contact-social-links">
          <li>
            <a href="https://www.linkedin.com/in/ceoturobov/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="contact-icon" /> LinkedIn
            </a>
          </li>
          <li>
            <a href="https://github.com/Muyinjon" target="_blank" rel="noopener noreferrer">
              <FaGithub className="contact-icon" /> GitHub
            </a>
          </li>
          <li>
            <a href="https://x.com/RealRapperM" target="_blank" rel="noopener noreferrer">
              <FaXTwitter className="contact-icon" /> Twitter
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/muindollar" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="contact-icon" /> Instagram
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@muindollar" target="_blank" rel="noopener noreferrer">
              <FaTiktok className="contact-icon" /> TikTok
            </a>
          </li>
        </ul>
        <div className="contact-info">
          <h2>
            <FaEnvelope className="contact-icon" /> Email:{" "}
            <a href="mailto:Ceoturobov@gmail.com" className="contact-link">
              Ceoturobov@gmail.com
            </a>
          </h2>
          <h2>
            Call me at:{" "}
            <a href="tel:+13474354914" className="contact-link">
              +1 (347) 435 - 4914
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
