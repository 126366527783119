// DownloadPage.js
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../styles/DownloadPage.css";

const DownloadPage = () => {
  const [downloadError, setDownloadError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const filePath = "https://github.com/Muyinjon/Codex-Merger/releases/download/v1.0.0/CodexMerger.exe";

  const handleDownload = () => {
    try {
      setIsDownloading(true);
      setDownloadError(false);

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", "CodexMerger.exe");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
    } catch (error) {
      console.error("Download failed:", error);
      setDownloadError(true);
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    // Automatically trigger the download on component mount
    handleDownload();
  }, []);

  return (
    <div className="download-page">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <h1>Thank You for Your Purchase!</h1>
      <p>Your download should start automatically. If it doesn't, click the button below.</p>

      <button onClick={handleDownload} className="download-button">
        Download Codex Merger
      </button>
      <a className="mirror-button" href="https://github.com/Muyinjon/Codex-Merger/releases/download/v1.0.0/CodexMerger.exe">
        Mirror
      </a>

      {downloadError && (
        <div className="error-message" style={{ color: "red", marginTop: "1rem" }}>
          Error: Unable to download the file. Please check if the file exists in the correct location or contact support.
        </div>
      )}
      <p>
        While your browser or antivirus software might flag this download as potentially harmful, we assure you that it is safe. Please follow the instructions
        below to proceed with the download.
      </p>
      <h2>Instructions to Allow the Download</h2>
      <div className="instructions">
        <h3>For Google Chrome:</h3>
        <ol>
          <li>After initiating the download, you might see a message saying the site can't be reached or the download was blocked.</li>
          <li>
            Click on the <strong>“Show all”</strong> button below the message.
          </li>
          <li>
            Find the blocked file (CodexMerger.exe) and click <strong>“Keep”</strong>.
          </li>
        </ol>

        <h3>For Mozilla Firefox:</h3>
        <ol>
          <li>After attempting the download, Firefox may block it and show a warning.</li>
          <li>
            Click on the <strong>“Allow Download”</strong> button.
          </li>
          <li>
            If the option isn't available, you may need to adjust your security settings by going to <strong>Options > Privacy & Security > Downloads</strong>{" "}
            and allowing downloads from this site.
          </li>
        </ol>

        <h3>For Microsoft Edge:</h3>
        <ol>
          <li>When the download is blocked, you’ll see a warning message.</li>
          <li>
            Click on <strong>“More actions”</strong> (three dots) next to the blocked download.
          </li>
          <li>
            Select <strong>“Keep”</strong> to proceed with the download.
          </li>
        </ol>

        <h3>For Windows Defender (Windows Security):</h3>
        <ol>
          <li>
            Open <strong>Windows Security</strong> by searching for it in the Start menu.
          </li>
          <li>
            Navigate to <strong>Virus & threat protection</strong>.
          </li>
          <li>
            Click on <strong>Manage settings</strong> under Virus & threat protection settings.
          </li>
          <li>
            Scroll down to <strong>Exclusions</strong> and click <strong>Add or remove exclusions</strong>.
          </li>
          <li>
            Click on <strong>Add an exclusion</strong> and select <strong>File</strong>.
          </li>
          <li>Navigate to your downloaded file (CodexMerger.exe) and select it to exclude it from scanning.</li>
        </ol>
      </div>
      <p>
        If you encounter any issues with the download, please contact us at <a href="mailto:ceoturobov@gmail.com">CeoTurobov@gmail.com</a>.
      </p>
    </div>
  );
};

export default DownloadPage;
