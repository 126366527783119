import React from "react";
import { Helmet } from "react-helmet-async";
import "../styles/Dislexia.css"; // Create this file for specific styles

const Dislexia = () => {
  return (
    <div className="App">
      <div className="Dislexia-page">
        <Helmet>
          {/* Primary Meta Tags */}
          <title>Dislexia Chrome Extension | Muyinjon Turobov</title>
          <meta
            name="description"
            content="Dislexia is a user-friendly Chrome extension designed to support individuals with dyslexia by enhancing their web browsing experience through innovative accessibility features."
          />
          <link rel="canonical" href="https://muyin.me/dislexia" />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="Dislexia Chrome Extension | Muyinjon Turobov" />
          <meta
            property="og:description"
            content="Enhance your web browsing experience with Dislexia, a Chrome extension offering Text-to-Speech, Speech-to-Text, and customizable text highlighting features for individuals with dyslexia."
          />
          <meta property="og:url" content="https://muyin.me/dislexia" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://muyin.me/images/social-image.jpg" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Muyin.me" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Dislexia Chrome Extension | Muyinjon Turobov" />
          <meta
            name="twitter:description"
            content="Enhance your web browsing experience with Dislexia, a Chrome extension offering Text-to-Speech, Speech-to-Text, and customizable text highlighting features for individuals with dyslexia."
          />
          <meta name="twitter:image" content="https://muyin.me/images/social-image.jpg" />

          {/* Structured Data (JSON-LD) for WebPage */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Dislexia Chrome Extension",
                "url": "https://muyin.me/dislexia",
                "description": "Dislexia is a Chrome extension that supports individuals with dyslexia by providing Text-to-Speech, Speech-to-Text, and customizable text highlighting features.",
                "mainEntity": {
                  "@type": "Person",
                  "name": "Muyinjon Turobov",
                  "jobTitle": "Developer & Entrepreneur",
                  "alumniOf": "CUNY Brooklyn College",
                  "sameAs": [
                    "https://www.linkedin.com/in/muyinjon-turobov",
                    "https://github.com/Muyinjon",
                    "https://x.com/RealRapperM",
                    "https://www.instagram.com/muindollar",
                    "https://www.tiktok.com/@muindollar"
                  ]
                }
              }
            `}
          </script>
        </Helmet>

        <h1 className="Dislexia-title">Dislexia Chrome Extension</h1>
        <div className="Dislexia-intro">
          <a
            href="https://chromewebstore.google.com/detail/dislexia/hglmojddcocplelfibmkjnnpmgifbkjd?hl=en"
            target="_blank"
            rel="noopener noreferrer"
            className="Dislexia-download-button"
          >
            Download Dislexia from the Chrome Web Store
          </a>
        </div>

        {/* Features Section */}
        <section className="Dislexia-features-section">
          <h2>Features</h2>
          <p>
            Dislexia is a user-friendly Chrome extension designed to support individuals with dyslexia by enhancing their web browsing experience through
            innovative accessibility features:
          </p>
          <ul>
            <li>
              <strong>Text-to-Speech (TTS):</strong> Reads selected text aloud using customizable settings for voice pitch, rate, and volume.
            </li>
            <li>
              <strong>Speech-to-Text (STT):</strong> Converts speech into text in active input fields, allowing users to dictate their thoughts.
            </li>
            <li>
              <strong>Overlay for Text Highlighting:</strong> Highlights selected text with customizable colors to improve focus and readability.
            </li>
            <li>
              <strong>Customizable User Preferences:</strong> Includes options for dark mode, light mode, highlighting opacity, and colors.
            </li>
            <li>
              <strong>Simple Activation:</strong> Easily enable or disable features and toggle the overlay through the settings.
            </li>
          </ul>
        </section>

        {/* Policy Section */}
        <section className="Dislexia-policy-section">
          <h2>Privacy Policy</h2>
          <p>At Dislexia, we value your privacy and are committed to protecting your data. Here’s how we handle user data:</p>
          <ul>
            <li>
              <strong>No Data Collection:</strong> The extension does not collect or store any personal data or browsing history.
            </li>
            <li>
              <strong>Local Settings Storage:</strong> User preferences like voice settings, highlighting options, and overlay activation status are stored
              locally on your device.
            </li>
            <li>
              <strong>No Third-Party Sharing:</strong> Dislexia does not share user data with third parties.
            </li>
            <li>
              <strong>Microphone Access:</strong> Microphone access is used solely for speech recognition during Speech-to-Text (STT) functionality. This data
              is processed locally and not sent to any server.
            </li>
          </ul>
          <p>
            By using the Dislexia Chrome Extension, you agree to the terms and privacy practices outlined in this policy. If you have any questions or concerns,
            please contact us at <a href="mailto:Ceoturobov@gmail.com">Ceoturobov@gmail.com</a>.
          </p>
          <p>
            For source code and contributions, visit our GitHub page at{" "}
            <a href="https://github.com/Muyinjon/Dislexia" target="_blank" rel="noopener noreferrer">
              Dislexia on GitHub
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default Dislexia;
