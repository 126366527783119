// src/sections/EducationSection.js
import React from "react";
import EducationCard from "../components/EducationCard";
import "./EducationSection.css"; // Import appropriate styles
import brooklynCollege from "../assets/images/brooklyn-College.jpg";
import plattsburgh from "../assets/images/plattsburgh.jpg";

const educationData = [
  {
    logo: brooklynCollege,
    institutionName: "CUNY Brooklyn College",
    location: "Brooklyn, NY",
    period: "2020 - 2023",
    degree: "Bachelor of Business Administration - BBA",
    gpa: "3.6",
    details: [
      "Student Athlete for Men’s Soccer Team",
      "Member of the Student Government as a Student Senator",
      "Member of Brooklyn College Innovation + Entrepreneurship Lab Club",
      "Dean’s List",
    ],
  },
  {
    logo: plattsburgh,
    institutionName: "SUNY Plattsburgh",
    location: "Plattsburgh, NY",
    period: "2019 - 2020",
    degree: "Bachelor of Business Administration - BBA",
    gpa: "3.7",
    details: [
      "Member of NABA (National Association of Black Accountants)",
      "Brother/Member of TKE (Tau Kappa Epsilon Fraternity)",
      "Member of Student Council",
      "Player in the Soccer Team",
      "Dean’s List",
    ],
  },
  // Add more education data here as needed
];

const EducationSection = () => {
  return (
    <section className="education-section">
      <h1 className="title-centered">🎓 My Education</h1>
      <p className="education-intro bounce-in">Embracing lifelong learning with versatility and a holistic perspective for enriched growth.</p>
      {educationData.map((edu, index) => (
        <div className="bounce-in">
          <EducationCard key={index} {...edu} />
        </div>
      ))}
    </section>
  );
};

export default EducationSection;
