// src/sections/ExperienceSection.js
import React from "react";
import { Helmet } from "react-helmet-async";
import ExperienceCard from "../components/ExperienceCard";
import "./ExperienceSection.css"; // Import your custom styles
import bnbjunk from "../assets/images/Bnbjunk.png";
import datasphere from "../assets/images/DataSphere.png";
import brooklynemerge from "../assets/images/brooklynemerge.png";
import blackstoneLaunchpad from "../assets/images/blackstone-launchpad.png";
import brooklynCollege from "../assets/images/brooklyn-College.jpg";
import fdc from "../assets/images/fdc.png";
import nyjtl from "../assets/images/nyjtl.jpeg";
import slapbox from "../assets/images/slapbox.png";
import tonysPizza from "../assets/images/tonypizza.png";
import freundFish from "../assets/images/Freundfish.jpeg";

const experienceData = [
  {
    logo: bnbjunk,
    companyName: "BNB Junk",
    companyType: "Junk Removal",
    period: "Jul 2023 – present",
    role: "Co-Founder",
    description: [
      "Founded and managed a junk removal business, overseeing customer acquisition, sales processes, and customer service, leading to consistent growth.",
      "Developed and executed data-driven marketing strategies to generate leads, achieving a 40% increase in inbound leads.",
      "Recruited, trained, and managed a team, enhancing customer interactions and ensuring a seamless service experience.",
      "Engaged directly with customers to understand their needs, providing customized solutions to increase loyalty and repeat business.",
    ],
    skills: "Customer Acquisition, Sales, Customer Service, Team Management",
  },
  {
    logo: datasphere,
    companyName: "Datasphere Global LLC",
    companyType: "Data Management",
    period: "Aug 2022 – Oct 2023",
    role: "Data Analyst Apprentice",
    description: [
      "Collaborated with sales and product teams to provide data-driven insights that supported pre-sales activities.",
      "Conducted comprehensive data analysis using SQL to identify key trends and customer pain points.",
      "Designed and implemented interactive dashboards with Amazon Quicksight for client meetings.",
      "Developed and automated VBA scripts for data processes, improving efficiency.",
    ],
    skills: "SQL, Data Analysis, Amazon Quicksight, VBA, Automation",
  },
  {
    logo: brooklynemerge,
    companyName: "Brooklyn Emerge (Muslims Vote Project)",
    companyType: "Non-Profit",
    period: "Apr 2023 – Nov 2023",
    role: "Program Director",
    description: [
      "Led community engagement efforts, conducting outreach and building relationships with key stakeholders.",
      "Designed and executed social media campaigns, resulting in a 60% increase in engagement.",
      "Facilitated workshops and events to engage the community effectively.",
    ],
    skills: "Community Engagement, Social Media, Event Planning, Public Speaking",
  },
  {
    logo: blackstoneLaunchpad,
    companyName: "Innovation + Entrepreneurship Lab by Blackstone Launchpad",
    companyType: "Non-Profit",
    period: "Jun 2022 – Jul 2023",
    role: "Ambassador Intern",
    description: [
      "Planned and organized entrepreneurship events, partnering with internal teams for smooth execution.",
      "Created marketing materials to promote entrepreneurship programs to students and external stakeholders.",
      "Managed a student community, improving participation and satisfaction through strategic engagement.",
    ],
    skills: "Event Planning, Marketing, Community Management, Strategy",
  },
  {
    logo: brooklynCollege,
    companyName: "Brooklyn College",
    companyType: "Education",
    period: "Aug 2021 – Dec 2021",
    role: "Data Science - Student Intern",
    description: [
      "Guided high school students in mastering Data Science using Python packages (Pandas, NumPy, Matplotlib, Scikit-learn).",
      "Performed exploratory data analysis (EDA) in the Billion Oyster Project, examining key parameters and deriving insights.",
      "Framed a hypothesis on oyster mortality, defining key steps for hypothesis testing and validating results with statistical methods.",
    ],
    skills: "Python, Data Science, EDA, Statistical Analysis",
  },
  {
    logo: fdc,
    companyName: "Flatbush Development Corporation (FDC)",
    companyType: "Non-Profit",
    period: "Apr 2022 – Aug 2022",
    role: "Activity Specialist",
    description: [
      "Supervised STEM and soccer activities for middle school students as an Activity Specialist.",
      "Developed and created curriculum and lesson plans for STEM and soccer.",
      "Maintained a safe environment by supervising students and addressing any issues.",
    ],
    skills: "STEM Education, Curriculum Development, Leadership",
  },
  {
    logo: nyjtl,
    companyName: "New York Junior Tennis & Learning (NYJTL)",
    companyType: "Non-Profit",
    period: "Oct 2021 – Apr 2022",
    role: "Group Leader",
    description: [
      "Provided feedback to parents on their child’s behavior and academic progress.",
      "Ensured the safety and appropriate behavior of students during transitions and classes.",
      "Supervised and accompanied students during special projects and field trips.",
    ],
    skills: "Youth Mentorship, Safety Supervision, Academic Support",
  },
  {
    logo: slapbox,
    companyName: "Music HeadQuarters | SlapBox Music Group",
    companyType: "Record Label",
    period: "2020 – 2021",
    role: "Social Media Manager | A&R intern",
    description: [
      "Managed social media accounts across platforms like Facebook, Instagram, TikTok, and SoundCloud.",
      "Increased the label’s social media following by 25% through engagement efforts and strategic management.",
      "Successfully signed multiple Hip-Hop artists to the record label as an A&R intern.",
    ],
    skills: "Social Media Management, Adobe Suite, A&R, Engagement Strategies",
  },
  {
    logo: tonysPizza,
    companyName: "TONY’S PIZZA",
    companyType: "Restaurant",
    period: "2018",
    role: "Assistant Cook",
    description: ["Prepared and organized ingredients for food preparation.", "Ensured quality control and maintained company equipment."],
    skills: "Food Preparation, Quality Control",
  },
  {
    logo: freundFish,
    companyName: "Freund's Fish Market",
    companyType: "Fish Store",
    period: "Sep 2015 – Jan 2018",
    role: "Delivery Boy",
    description: ["Delivered orders to customers and discussed their orders.", "Collected payments and provided excellent customer service."],
    skills: "Customer Service, Cash Handling, Delivery",
  },
];

const ExperienceSection = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: experienceData.map((exp, index) => ({
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@type": "Organization",
        name: exp.companyName,
        description: exp.companyType,
        logo: exp.logo, // Ensure this is a URL
        url: `https://muyin.me/experience/${exp.companyName.replace(/\s+/g, "-").toLowerCase()}`, // Adjust URL accordingly
        jobTitle: exp.role,
        startDate: exp.period.split(" – ")[0],
        endDate: exp.period.split(" – ")[1] === "present" ? "Present" : exp.period.split(" – ")[1],
        skills: exp.skills.split(", ").map((skill) => ({ "@type": "DefinedTerm", name: skill })),
        description: exp.description.join(" "),
      },
    })),
  };

  return (
    <section className="experience-section bounce-in" aria-labelledby="experience-heading">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <header>
        <h2 id="experience-heading">💼 My Experience</h2>
        <p className="experience-intro bounce-in">Navigating diverse environments with adaptability and expertise for holistic solutions.</p>
      </header>
      <div className="experience-cards">
        {experienceData.map((exp, index) => (
          <article className="experience-card-wrapper bounce-in" key={index}>
            <ExperienceCard
              logo={exp.logo}
              companyName={exp.companyName}
              companyType={exp.companyType}
              period={exp.period}
              role={exp.role}
              description={exp.description}
              skills={exp.skills}
            />
          </article>
        ))}
      </div>
    </section>
  );
};

export default ExperienceSection;
